@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'Gokschil';
    src: url('./assets/fonts/gokschil.otf') format('truetype');
}
@font-face {
    font-family: 'EvgenyBold';
    src: url('./assets/fonts/EvgenyBold.ttf') format('truetype');
}

.container {
    max-width: calc(100vw - 6%);
    /* Subtract 6% from viewport width */
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
    font-family: Gokschil;
    letter-spacing: 1px;
    margin: 0px;
}

span,
p,
ul li,
a {
    font-family: "Montserrat", sans-serif;
    margin: 0px;
}

ul {
    padding-left: 1rem;
}

section{
    padding: 60px 0px;
}

#home span.title{
    font-family: EvgenyBold;
    text-transform: uppercase;
    font-size: 28px;
    color: #FE475D;
}
