#home {
    background: linear-gradient(to right, #EF5090 50%, #fff 50%);
}

#home .right {
    padding: 0px 5%;
    background: url('../assets//media/right-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right;
}

#home h1 {
    text-transform: uppercase;
    font-size: 3rem;
    margin-bottom: 40px;
}

#home .sns {
    margin-top: 30px;
}

#home .sns button {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    padding: 12px 35px;
    border-radius: 20px;
    background: transparent;
    border: 3px solid #EF5090;
    margin-right: 10px;
}

#home .sns a img {
    max-width: 60px;
    margin-right: 6px;
}

.divider {
    padding: 10px;
    background-color: #1F2031;
}

#story {
    background: linear-gradient(to right, #FCF9F7 50%, #F7F0E9 50%);
    padding-bottom: 0px;
}

#story .left {
    background: url('../assets/media/bg-x.png');
    background-size: 100px;
    background-repeat: no-repeat;
    background-position: top right;
    max-width: 700px;
}

#story .left h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: left;
}

#story p {
    margin-bottom: 10px;
    font-weight: 600;
    padding-right: 60px;
}

#story p span {
    color: red;
}

#story .right .story-img {
    max-width: 60%;
}

#tokenomics {
    background: linear-gradient(to right, #F7F0E9 50%, #FCF9F7 50%);
}

#tokenomics .supply{
    max-width: 35%;
}


.tooltip-show,
.presale-tooltip-show {
    display: block;
    position: absolute;
    /* top: -50px; */
    /* Adjust the position as needed */
    left: 50%;
    /* Adjust the position as needed */
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 999;
}

.tooltip-hide,
.presale-tooltip-hide {
    display: none;
}

#tokenomics .contract-title {
    text-transform: uppercase;
    margin: 10px 0px;
}

#tokenomics .contract-box {
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: fit-content;
    padding: 10px;
    border: 3px solid #EF5090;
    border-radius: 12px;
}

#tokenomics .copy-btn {
    border: none;
    background: transparent;
}

#tokenomics .right {
    padding-left: 5%;
}

#tokenomics h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: left;
}

#tokenomics .boxes img {
    width: 100%;
    height: auto;
}

#tokenomics .mhj-img img {
    max-width: 50%;
}

#nft {
    padding-bottom: 0px;
    margin-bottom: -120px;
    background-color: #F7F0E9;
}

#nft h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

#nft .section-desc {
    font-weight: 600;
}

#nft .nfts {
    margin-top: 40px;
}

#nft .nfts img {
    max-width: 330px;
    padding: 10px;
}

.divider-2 {
    padding: 10px;
    background-color: #EF5090;
}

#roadmap {
    padding-top: 200px;
    background-image: url('../assets/media/roadmap-stroke.png');
    background-position: 0 310px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #1F2031;
}

#roadmap h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;

}

#roadmap .title {
    margin-bottom: 10%;
}

#roadmap .title span {
    color: #FE475D;
    font-weight: 800;
}

#roadmap .box-1,
#roadmap .box-2,
#roadmap .box-3 {
    padding: 20px;
}

#roadmap .arrow-list {
    font-size: 14px;
    list-style-type: none;
    padding-left: 0;
    color: #fff;
    font-weight: 600;
}

#roadmap .arrow-list li {
    margin-bottom: 8px;
}

#roadmap .arrow-list li:before {
    content: "→ ";
}

#roadmap .map-2 {
    margin-top: 23%;
}

#roadmap .map-3 {
    margin-top: 46%;
}

#conference {
    background-color: #F6EEE7;
}

#featured {
    padding-top: 0px;
    padding-bottom: 60px;
    background-color: #F6EEE7;
}

#featured h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
}

#featured .bg-white {
    padding: 60px 0px;
    border-radius: 0px 0px 12px 12px;
}

#featured .first-row,
#featured .second-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

#featured .first-row img,
#featured .second-row img {
    max-width: 250px;
    padding: 10px;
}

@media only screen and (max-width: 768px) {
    #home {
        background: linear-gradient(to bottom, #fff 65%, #EF5090 65%);
        padding-bottom: 20px;
    }

    #home h1 {
        font-size: 2.2rem;
    }

    #home .sns button {
        padding: 8px 14px;
        border-radius: 20px;
        background: transparent;
        border: 3px solid #EF5090;
        margin-right: 10px;

    }

    #home .sns a img {
        max-width: 45px;
    }

    #story {
        background: #F7F0E9;
        padding-bottom: 0px;
    }

    #story p {
        padding: 0px;
    }

    #story .right .story-img {
        max-width: 100%;

    }

    #tokenomics {
        background: #FCF9F7;
    }

    #tokenomics .supply{
        max-width: 65%;
    }

    #tokenomics .mhj-img img {
        max-width: 100%;
    }

    #tokenomics .contract-box p {
        font-size: 8px;
    }

    #nft{
        margin-bottom: 0px;
        padding-bottom: 60px;
    }

    #roadmap {
        background-image: none;
        padding-top: 60px;
    }

    #roadmap .title {
        margin-bottom: 10px;
    }

    #roadmap .box-1,
    #roadmap .box-2,
    #roadmap .box-3 {
        padding: 0px;
    }

    #roadmap .map-2, #roadmap .map-3 {
        margin-top: 40px;
    }



}