.navbar {
    background: linear-gradient(to right, #EF5090 50%, #fff 50%);
    padding: 0px;
    /* align-items: start; */
}

.navbar .navbar-collapse {
    margin-left: 10%;
    padding: 20px 10%;
    background-color: #000;
    border-radius: 0px 0px 30px 30px;
}

.navbar .navbar-brand img{
    margin-top: 10px;
    max-width: 100px;
}

.navbar ul li a {
    color: #fff;
    font-weight: 600;
}

.navbar ul li a:hover{
    color: #EF5090;
}

@media only screen and (max-width: 768px) {

    .navbar {
        background: #1F2031;
        padding: 0px;
        /* align-items: start; */
    }

    .navbar .navbar-brand img{
        margin-top: 10px;
        max-width: 80px;
    }

    .navbar-toggler:focus{
        box-shadow: none;
    }

    .fa-bars{
        font-size: 28px;
    }

    .navbar .navbar-collapse{
        margin: 0px;
        background-color: transparent;
        padding: 20px;
        font-size: 18px;
    }

}