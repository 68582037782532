footer{
    padding: 60px 0px;
    background: linear-gradient(to bottom, #EF5090 45%, #1F2031 45%);
}

footer .footer-logo{
    max-width: 100px;
}

footer span.title{
    font-family: EvgenyBold;
    text-transform: uppercase;
    font-size: 3rem;
    color: #fff;
}

footer .sns a img{
    max-width: 80px;
    padding: 10px;
}

#copyright{
    background-color: #1F2031;
    padding-top: 0px;
    padding-bottom: 20px;
}

#copyright .container{
    max-width: 580px;
}

@media only screen and (max-width: 768px) {
    footer{
        padding: 60px 0px;
        background: #EF5090;
    }
    
    footer .footer-logo{
        max-width: 150px;
       
    }

    footer .sns a img{
        max-width: 60px;
        padding: 10px;
    }
}